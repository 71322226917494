export class Terms {
  type: string = 'minutes';
  value: number = 0;
}

export class PolicyForm {
  is_enabled: boolean = true;
  description: string;
  terms: Terms = new Terms();
}

export class Policy {
  reschedule: PolicyForm = new PolicyForm();
  cancellation: PolicyForm = new PolicyForm();
}
