export class Location {
  type?: string;
  name?: string;
  email?: string;
  phone?: string;
  icon?: string;
  address?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  place_id?: string;
  join_url?: string;
  remarks?: string;
  meeting_id?: string;
  password?: string;
}
