<div class="modal-header">
  <div class="modal-title h5">{{ title }}</div>
  <button type="button" class="btn-close" aria-describedby="modal-title" (click)="activeModal.dismiss()">
    
  </button>
</div>
<form #ngForm (ngSubmit)="verifyOtp()" ngNativeValidate>
  <div class="modal-body">
    <div class="form-group mb-3">
      <p>{{ description }}</p>
    </div>
    <div class="form-group mb-3">
      <label for="otp" class="fw-bold">Verification code</label>
      <input type="number" class="form-control" [(ngModel)]="otp.code" name="otp" minlength="4" maxlength="6" required />
      <p class="text-muted small">Enter the one time verification(OTP) sent on your email</p>
    </div>
  </div>
  <div class="modal-footer py-1">
    <button class="btn col" [ngClass]="btnClass" type="submit">
      {{ btnText }}
      <i class="spinner-border spinner-border-sm" role="status" *ngIf="loading"></i>
    </button>
  </div>
</form>
