import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { HostDTO } from '../model/models';
import { dialCodes } from './dialCodes';
import { readableColor, hsla, parseToHsla } from 'color2k';

@Injectable({
  providedIn: 'root',
})
export class Utility {
  constructor() {}

  convertToMinutes = (duration: any | number) => {
    if (typeof duration == 'number') return duration;

    if (duration.type === 'hours') {
      return duration.value * 60;
    } else return duration.value;
  };

  slugify(value: string, replacement = '-') {
    let slug = value.replace(/^\s+|\s+$/g, '');
    slug = slug.toLowerCase();
    slug = slug
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, replacement)
      .replace(/-+/g, replacement);
    return slug.length > 0
      ? slug
      : Math.random().toString(36).split('.')[1].toUpperCase().substring(0, 10);
  }

  getLocationOptions() {
    return [
      {
        name: 'Google Meet',
        type: 'google_meet',
        icon: 'https://cdn.dayschedule.com/icon/google-meet.png',
        remarks: 'Web conference using Google meet',
      },
      {
        name: 'Zoom',
        type: 'zoom',
        icon: 'https://cdn.dayschedule.com/icon/zoom.png',
        remarks: 'Web conference using Zoom',
      },
      {
        name: 'Microsoft Teams',
        type: 'microsoft_teams',
        icon: 'https://cdn.dayschedule.com/icon/microsoft-teams.png',
        remarks: 'Web conference using Microsoft Teams',
      },
      {
        name: 'Zoho meeting',
        type: 'zoho',
        icon: 'https://cdn.dayschedule.com/icon/zoho.png',
        remarks: 'Web conference using Zoho meeting',
      },
      {
        name: 'Inbound Call',
        type: 'inbound_call',
        icon: 'https://cdn.dayschedule.com/icon/phone.png',
        remarks: 'Ask phone number to your invitees',
      },
      {
        name: 'Goto Meeting',
        type: 'goto_meeting',
        icon: 'https://cdn.dayschedule.com/icon/goto-meeting.png',
        remarks: 'Web conference using Goto Meeting',
      },
      {
        name: 'Webex',
        type: 'webex',
        icon: 'https://cdn.dayschedule.com/icon/webex.png',
        remarks: 'Web conference using Webex Meeting',
      },
      {
        name: 'Join me',
        type: 'joinme',
        icon: 'https://cdn.dayschedule.com/icon/joinme.png',
        remarks: 'Web conference using Join me',
      },
      {
        name: 'Ask Invitee',
        type: 'ask_invitee',
        icon: 'https://cdn.dayschedule.com/icon/ask.png',
        remarks: 'Invitees can set the custom location',
      },
    ];
  }

  getBusinessHours() {
    return [
      {
        is_available: false,
        day: 'SUN',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
      {
        is_available: true,
        day: 'MON',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
      {
        is_available: true,
        day: 'TUE',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
      {
        is_available: true,
        day: 'WED',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
      {
        is_available: true,
        day: 'THU',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
      {
        is_available: true,
        day: 'FRI',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
      {
        is_available: false,
        day: 'SAT',
        times: [
          {
            start: '09:00',
            end: '18:00',
          },
        ],
      },
    ];
  }

  getTemplates() {
    return [
      {
        name: 'Default',
        value: 'default',
        logo: '',
      },
      {
        name: 'Classic',
        value: 'classic',
        logo: '',
      },
      {
        name: 'Sunset',
        value: 'sunset',
        logo: '',
      },
    ];
  }

  getCurrencies() {
    return [
      { name: 'AED' },
      { name: 'AMD' },
      { name: 'AOA' },
      { name: 'ARS' },
      { name: 'AUD' },
      { name: 'AZN' },
      { name: 'BAM' },
      { name: 'BBD' },
      { name: 'BGN' },
      { name: 'BHD' },
      { name: 'BMD' },
      { name: 'BOB' },
      { name: 'BRL' },
      { name: 'BWP' },
      { name: 'BYN' },
      { name: 'CAD' },
      { name: 'CDF' },
      { name: 'CHF' },
      { name: 'CLP' },
      { name: 'CNY' },
      { name: 'COP' },
      { name: 'CRC' },
      { name: 'CVE' },
      { name: 'CZK' },
      { name: 'DKK' },
      { name: 'DOP' },
      { name: 'EGP' },
      { name: 'EUR' },
      { name: 'FJD' },
      { name: 'GBP' },
      { name: 'GEL' },
      { name: 'GHS' },
      { name: 'GIP' },
      { name: 'GTQ' },
      { name: 'GYD' },
      { name: 'HKD' },
      { name: 'HNL' },
      { name: 'HRK' },
      { name: 'HUF' },
      { name: 'IDR' },
      { name: 'ILS' },
      { name: 'IMP' },
      { name: 'INR' },
      { name: 'IQD' },
      { name: 'ISK' },
      { name: 'JMD' },
      { name: 'JOD' },
      { name: 'JPY' },
      { name: 'KES' },
      { name: 'KGS' },
      { name: 'KHR' },
      { name: 'KRW' },
      { name: 'KWD' },
      { name: 'KYD' },
      { name: 'KZT' },
      { name: 'LAK' },
      { name: 'LBP' },
      { name: 'LKR' },
      { name: 'LYD' },
      { name: 'MAD' },
      { name: 'MDL' },
      { name: 'MGA' },
      { name: 'MKD' },
      { name: 'MMK' },
      { name: 'MNT' },
      { name: 'MOP' },
      { name: 'MRU' },
      { name: 'MUR' },
      { name: 'MVR' },
      { name: 'MWK' },
      { name: 'MXN' },
      { name: 'MYR' },
      { name: 'MZN' },
      { name: 'NAD' },
      { name: 'NGN' },
      { name: 'NIO' },
      { name: 'NOK' },
      { name: 'NZD' },
      { name: 'OMR' },
      { name: 'PAB' },
      { name: 'PEN' },
      { name: 'PGK' },
      { name: 'PHP' },
      { name: 'PKR' },
      { name: 'PLN' },
      { name: 'PYG' },
      { name: 'QAR' },
      { name: 'RON' },
      { name: 'RSD' },
      { name: 'RUB' },
      { name: 'RWF' },
      { name: 'SAR' },
      { name: 'SEK' },
      { name: 'SGD' },
      { name: 'SZL' },
      { name: 'THB' },
      { name: 'TJS' },
      { name: 'TMT' },
      { name: 'TND' },
      { name: 'TRY' },
      { name: 'TTD' },
      { name: 'TWD' },
      { name: 'TZS' },
      { name: 'UAH' },
      { name: 'UGX' },
      { name: 'USD' },
      { name: 'UYU' },
      { name: 'UZS' },
      { name: 'VEF' },
      { name: 'VND' },
      { name: 'XAF' },
      { name: 'XCD' },
      { name: 'XOF' },
      { name: 'ZAR' },
      { name: 'ZMW' },
      { name: 'ZWD' },
    ];
  }

  ngSelectAll(items: any[]) {
    const allSelect = (items: any[]) => {
      items.forEach((element: any) => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  reduceUserToHost(users: Array<User>): Array<HostDTO> {
    return users.map((u: User) => {
      return {
        user_id: u.user_id,
        name: u.name,
        email: u.email,
        avatar: u.avatar,
        phone: u.phone,
        time_zone: u.time_zone,
      };
    });
  }

  uniqueDialCodes() {
    return [...new Set(Object.values(dialCodes))];
  }

  extractDialCode(phoneNumber: string | undefined) {
    if (!phoneNumber) return;

    const parts = phoneNumber?.split(' ');
    if (!parts.length || parts.length < 2) return;

    return { dialCode: parts[0], number: parts.slice(1).join('') };
  }

  getPlaceholderSchedule(userId: number, timeZone: string) {
    return {
      user_id: userId,
      name: 'My schedule',
      availability: this.getBusinessHours(),
      time_zone: timeZone,
    };
  }

  // Based on https://dev.to/angular/how-to-implement-heatmap-in-tables-using-directives-in-angular-2f60
  getColor(value: number, highestValue: number) {
    const color = '#0b8bfb'; // '#309c39';
    const [h, s, l, a] = parseToHsla(color);
    const maxLightness = 1 - l;
    const percentage = (value * maxLightness) / highestValue;
    const lightness = +percentage.toFixed(3);
    const bgColor = hsla(h, s, 1 - lightness, a);
    return {
      bgColor: bgColor,
      color: readableColor(bgColor),
    };
  }
}
