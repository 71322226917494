export class Paged {
  offset: number = 0;
  limit: number = 15;
  sort?: string;
  order?: string;
  search?: string;

  user_id?: number;
  email?: string; // To filter bookings
  start_at?: string; // To filter bookings
  end_at?: string; // To filter bookings
}
