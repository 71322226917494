<div class="row m-0 vh-100">
  <div class="col-lg-4 col-md-5 bg-brand p-0">
    <div class="mt-5 mx-4 mx-md-5">
      <img
        class="img-fluid"
        width="200"
        height="auto"
        src="https://cdn.dayschedule.com/img/dayschedule-logo.png"
        alt="DaySchedule"
      />
    </div>
    <div class="card my-3 mx-4 mx-md-5">
      <div class="card-body">
        <h1 class="h3">Login</h1>
        <p>
          New to Dayschedule ?
          <a routerLink="/signup">Create an account &rarr;</a>
        </p>
        <a
          class="btn mt-2 px-0"
          [href]="googleAuthUri"
          aria-describedby="Sign in with Google"
        >
          <img src="../../static/img/btn_google_signin_dark_normal_web.png" />
        </a>
        <div class="or-container">
          <p class="or mb-0">or</p>
        </div>
        <label class="fw-bold mb-1">Sign in with email</label>
        <form
          class="user"
          #form="ngForm"
          (ngSubmit)="onSubmit(form)"
          autocomplete="on"
          ngNativeValidate
        >
          <div class="form-group mb-3">
            <input
              type="email"
              placeholder="Email*"
              class="form-control"
              id="email"
              aria-describedby="email"
              name="email"
              [(ngModel)]="user.email"
              required
            />
          </div>
          <div class="form-group mb-3">
            <input
              type="password"
              placeholder="Password*"
              class="form-control"
              name="password"
              id="password"
              minlength="6"
              aria-describedby="password"
              [(ngModel)]="user.password"
              required
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary w-100"
            [disabled]="loading"
          >
            <i
              class="spinner-border spinner-border-sm"
              role="status"
              *ngIf="loading"
            ></i>
            Sign in
          </button>
        </form>
        <hr />

        <div class="mt-3 pb-2">
          Forgot your password?
          <a routerLink="/forgot-password">Reset here &rarr;</a>
        </div>
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>

  <div class="col-lg-8 col-md-7 d-none d-md-block bg-body border-start">
    <app-auth-sidebar></app-auth-sidebar>
  </div>
</div>
