<div class="container-fluid">
  <!-- Outer Row -->
  <div class="row mt-5 mb-5">
    <div class="col-md-6 offset-md-3">
      <div class="card shadow">
        <div class="card-header text-center bg-brand">
          <img width="200" height="auto" class="img-fluid" src="https://cdn.dayschedule.com/img/dayschedule-logo.png" alt="DaySchedule" />
        </div>
        <div class="card-body">
          <div class="text-center" *ngIf="loading">
            <button class="btn btn-outline-primary btn-circle btn-lg">
              <i class="spinner-border spinner-border-sm"></i>
            </button>
          </div>
          <div class="text-center" *ngIf="!loading">
            <button class="btn btn-success btn-circle btn-lg" *ngIf="status == 200">
              <i class="bi bi-check-lg"></i>
            </button>
            <button class="btn btn-danger btn-circle btn-lg" *ngIf="status != 200">
              <i class="bi-exclamation-triangle"></i>
            </button>
          </div>
          <div class="text-center mt-5">
            <h1 class="h4 mb-4" [ngClass]="{ 'text-danger': status != 200, '': status <= 201 }">{{ message }}</h1>
            <p class="lead mb-5" *ngIf="status == 0">Loading...</p>
          </div>

          <hr />
          <div class="text-center">
            <a routerLink="/login">&larr; Back to Login</a>
            <a target="_blank" class="ms-3" href="https://dayschedule.com/contact">Contact Support &rarr;</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
