<div class="row m-0 vh-100">
  <div class="col-lg-4 col-md-5 bg-brand p-0">
    <div class="mt-5 mx-4 mx-md-5">
      <img
        class="img-fluid"
        width="200"
        height="auto"
        src="https://cdn.dayschedule.com/img/dayschedule-logo.png"
        alt="DaySchedule"
      />
    </div>
    <div class="card my-3 mx-4 mx-md-5">
      <div class="card-body">
        <h1 class="h3 mb-3">Reset your password</h1>
        <form
          class="user"
          #form="ngForm"
          (ngSubmit)="onSubmit(form)"
          ngNativeValidate
        >
          <div class="form-group mb-3">
            <input
              type="password"
              placeholder="Password*"
              class="form-control"
              name="password"
              id="password"
              [(ngModel)]="user.password"
              required
              minlength="8"
            />
          </div>
          <div class="form-group mb-3">
            <input
              type="password"
              placeholder="Confirm Password*"
              class="form-control"
              name="confirmpassword"
              id="confirmpassword"
              [(ngModel)]="user.confirm_password"
              required
              minlength="8"
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary w-100"
            [disabled]="loading"
          >
            <i
              class="spinner-border spinner-border-sm"
              role="status"
              *ngIf="loading"
            ></i>
            Reset Password
          </button>
        </form>
        <hr />
        <div>
          Already have an account? <a routerLink="/login">Go to Login &rarr;</a>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-8 col-md-7 d-none d-md-block bg-body border-start">
    <app-auth-sidebar></app-auth-sidebar>
  </div>
</div>
