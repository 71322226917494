import { Question } from './question';

export class Page {
  page_id?: number;
  account_id?: string;
  user_id?: string;
  name?: string;
  color: Color = new Color();
  seo: SEO = new SEO();
  integrations: any;
  template: Template = new Template();
  is_default: boolean = false;
  is_public: boolean = false;
  remove_branding: boolean = false;
  screenshots: Array<string> = [];
  domain?: string;
  logo?: string;
  shape?: string;
  description?: string;
  analytics: Analytics;
  created_at?: Date;
  updated_at?: Date;
  url?: string;
  clicks?: number;
}

export class Analytics {
  domain?: string;
  visits?: number;
  pageviews?: number;
}

export enum ColorMode {
  light = 'light',
  dark = 'dark',
  auto = 'auto',
}

export class Color {
  primary: string = '#0f0980';
  secondary: string = '#afeefe';
  mode: ColorMode = ColorMode.light;
}

export class SEO {
  title?: string;
  description?: string;
  crawl?: boolean;
}

export class Template {
  name: string;
  is_public?: boolean = false;
  blocks: Array<Blocks> = new Array<Blocks>();
}

export class Blocks {
  name: string;
  type: BlockTypes;
  content?: string;
  images?: Array<string>;
  border?: boolean = false;
  shadow?: boolean = false;
  image?: string;
  padding?: number = 0;
  title?: Title;
  subtitle?: string;
  team_members?: Array<TeamMember>;
  social?: Array<SocialPreference>;
  links?: Array<Links>;
  logo?: string;
  position?: Positions;
  buttons?: Array<Button>;
  address?: Address;
  link_groups?: Array<LinkGroup>;
  icon?: string;
}

export class Navigation {
  name: string = 'Navigation';
  styles?: Style = new Style();
  logo?: string = 'https://cdn.dayschedule.com/template/logo.png';
  links?: Array<Links> = new Array<Links>();
  type: BlockTypes = BlockTypes.navigation;
}

export class Footer {
  name: string = 'Footer';
  logo?: string = 'https://cdn.dayschedule.com/template/logo.png';
  title?: Title = new Title();
  subtitle?: string;
  styles: Style = new Style();
  position: Positions = Positions.left;
  link_groups?: Array<LinkGroup> = new Array<LinkGroup>();
  social?: Array<SocialPreference> = new Array<SocialPreference>();
  type: BlockTypes = BlockTypes.footer;
}

export class Content {
  name: string = 'Content';
  title?: Title = new Title();
  subtitle?: string = 'Section Subtitle';
  styles: Style = new Style();
  image?: string = 'https://via.placeholder.com/824x552';
  position: Positions = Positions.right;
  links?: Array<Links> = new Array<Links>();
  type: BlockTypes = BlockTypes.content;
}

export class Gallery {
  name: string = 'Gallery';
  title?: Title = new Title();
  subtitle?: string = 'Gallery Section Subtitle';
  images?: Array<string> = [];
  position?: Positions = Positions.left;
  styles: Style = new Style();
  type: BlockTypes = BlockTypes.gallery;
}

export class Team {
  name: string = 'Team';
  title?: Title = new Title();
  subtitle?: string = 'Team Section Subtitle';
  members?: Array<TeamMember> = new Array<TeamMember>();
  styles: Style = new Style();
  type: BlockTypes = BlockTypes.team;
}

export class Form {
  name: string = 'Form';
  title?: Title = new Title();
  subtitle?: string = 'Your Section Subtitle';
  users?: any[] = [];
  styles: Style = new Style();
  questions: Array<Question> = new Array<Question>();
  position?: Positions = Positions.bottom;
  type: BlockTypes = BlockTypes.form;
}

export class Title {
  type: string = 'h2';
  value: string = 'Your Title';
}

export class Videos {
  name: string = 'Videos';
  title?: Title = new Title();
  subtitle?: string;
  styles: Style = new Style();
  links?: Array<Links> = new Array<Links>();
  type: BlockTypes = BlockTypes.videos;
}

export class Map {
  name: string = 'Map';
  title?: Title = new Title();
  subtitle?: string = 'Your Section Subtitle';
  styles: Style = new Style();
  position?: Positions = Positions.left;
  link: string;
  address?: Address = new Address();
  type: BlockTypes = BlockTypes.map;
}

export class Address {
  location?: string = '48, 1st Floor, Omaxe Galleria Sector 14, Bahadurgarh Haryana';
  email?: string = 'John doe @gmail.com';
  phone?: string = '+91- 900000000';
}

export class BusinessAvailability {
  name: string = 'Availability';
  title?: string;
  subtitle?: string;
  availability?: Array<any>;
  type: BlockTypes = BlockTypes.team;
}

export class LinkGroup {
  name?: string;
  links?: Array<Links>;
}

export class Links {
  name?: string;
  type?: string;
  action?: string;
  scroll_section?: string;
  external_url?: string;
}

export class Button {
  text?: string;
  action?: string;
}

export class TeamMember {
  image?: string = 'https://s3.amazonaws.com/us-east-1.data.dayschedule.com/uploads/org-35/Avatar/64-1640151973399-testimonial-2.jpg';
  name?: string;
  title?: string;
  description?: string;
  social?: Array<SocialPreference> = new Array<SocialPreference>();
}

export class SocialPreference {
  type?: string;
  link?: string;
}

export class Style {
  backgroundColor: string = '#ffffff';
  color: string = '#000000';
  paddingTop: string = '10px';
  paddingBottom: string = '10px';
}

export enum Positions {
  left,
  right,
  top,
  bottom,
}

export enum BlockTypes {
  navigation = 'navigation',
  availabilty = 'availabilty',
  team = 'team',
  gallery = 'gallery',
  reviews = 'reviews',
  footer = 'footer',
  map = 'map',
  form = 'form',
  content = 'content',
  videos = 'videos',
}
