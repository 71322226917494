export class Link {
  url?: string;
  text?: string;
}

export class Redirect {
  url?: string;
}

export enum ConfirmationType {
  thanks = 'thanks',
  link = 'link',
  redirect = 'redirect',
}

export class Confirmation {
  type?: ConfirmationType = ConfirmationType.thanks;
  link: Link = new Link();
  redirect: Redirect = new Redirect();
  message: string;
}
