export enum PrivacyType {
  public = 'public',
  private = 'private',
  protected = 'protected',
}

export class Privacy {
  type: PrivacyType = PrivacyType.public;
  passcode: string;
}
