export enum DurationType {
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
}

export class Duration {
  type: DurationType = DurationType.minutes;
  value: number;
}
