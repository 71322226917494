import { Confirmation } from './confirmation';
import { Guest } from './guest';
import { Policy } from './policy';
import { Privacy } from './privacy';

export class ResourceSettings {
  confirmation: Confirmation = new Confirmation();
  attendees?: Array<string> = [];
  guest: Guest = new Guest();
  privacy: Privacy = new Privacy();
  policy: Policy = new Policy();
  require_approval?: boolean;
  check_calendar_conflict?: boolean = true;
  prevent_duplicates?: boolean;
  email_to_invitee?: boolean = true;
  calendar_invite_to_invitee?: boolean = true;
  email_to_host?: boolean = true;
  date_format?: string = 'MMM, DD YYYY';
  time_format?: string = 'h:mm a';
  is_timezone_locked: boolean = false;
  hide_timezone: boolean = false;
  hide_language: boolean = false;
  skip_form: boolean = false;
  short_link_only: boolean = false;
}
