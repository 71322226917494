import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ErrorInterceptor } from './auth/error.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import { TopbarComponent } from './home/topbar/topbar.component';
import { LoginComponent } from './auth/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ResetComponent } from './auth/reset/reset.component';
import { JoinComponent } from './auth/join/join.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VerificationComponent } from './auth/verification/verification.component';
import { AuthSidebarComponent } from './auth/auth-sidebar/auth-sidebar.component';
import { RedeemCouponComponent } from './redeem-coupon/redeem-coupon.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MenuComponent } from './home/menu/menu.component';
import { EmbedComponent } from './home/embed/embed.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { GlobalErrorHandler } from './auth/global-error-handler';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

@NgModule({
  declarations: [
    AppComponent,
    AuthSidebarComponent,
    SidebarComponent,
    TopbarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignupComponent,
    ResetComponent,
    JoinComponent,
    NotFoundComponent,
    VerificationComponent,
    RedeemCouponComponent,
    MenuComponent,
    EmbedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    RecaptchaV3Module,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaV3Key },
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
