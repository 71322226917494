export enum CapacityType {
  perday = 'perday',
  perslot = 'perslot',
  overall = 'overall',
}

export class Capacity {
  is_unlimited?: boolean = true;
  type: CapacityType;
  max?: number;
  is_public: boolean;
}
