export enum PeriodType {
  calendar_days = 'calendar_days',
  business_days = 'business_days',
  ranges = 'ranges',
  specific = 'specific',
  any = 'any',
}

export class SpecificDays {
  type?: string;
  weekday?: string;
  date?: Date;
  time?: string;
  time_zone?: string;
}

export class Period {
  type?: PeriodType = PeriodType.calendar_days;
  days?: number = 60;
  start_date?: Date;
  end_date?: Date;
  specific: Array<SpecificDays> = new Array<SpecificDays>();
}
