import { RoleType } from './user';

export class Apikey {
  key_id: number;
  account_id?: number;
  user_id?: number;
  name?: string;
  key?: string;
  role?: RoleType = RoleType.Admin;
  permissions?: string;
  is_enabled: boolean = true;
  created_at?: Date;
  updated_at?: Date;
}
