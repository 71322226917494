import { Banner } from './banner';
import { HostDTO } from './booking';
import { Notice } from './notice';
import { Payment, Price } from './payment';
import { Period } from './period';
import { Resource, ResourceType } from './resource';
import { Capacity, CapacityType } from './capacity';
import { Duration, DurationType } from './duration';

export class Webinar extends Resource {
  period: Period = new Period();
  notice: Notice = new Notice();
  durations?: Array<Duration> = [{ type: DurationType.minutes, value: 30 }];
  capacity: Capacity = {
    type: CapacityType.perslot,
    max: 100,
    is_public: true,
  };
  payment: Payment = new Payment();
  prices: Array<Price> = new Array<Price>();
  members?: Array<HostDTO> = [];
  banner: Banner = new Banner();
}
