import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorModeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setupColorMode() {
    let settings = localStorage.getItem('lightSwitch');
    if (settings == null) {
      settings = this.getSystemDefaultTheme();
    }
    if (settings == 'dark') {
      this.darkMode();
    } else {
      this.lightMode();
    }
  }

  getSystemDefaultTheme() {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkThemeMq.matches) {
      return 'dark';
    }
    return 'light';
  }

  public autoMode() {
    const settings = this.getSystemDefaultTheme();
    if (settings == 'dark') {
      this.darkMode();
    } else {
      this.lightMode();
    }
  }

  public lightMode() {
    this.document.querySelectorAll('[data-bs-theme]').forEach((element) => {
      element.setAttribute('data-bs-theme', 'light');
    });

    this.document.querySelectorAll("[class*='-dark']").forEach((element) => {
      element.className = element.className.replace(/-dark/g, '-light');
    });

    localStorage.setItem('lightSwitch', 'light');
  }

  public darkMode() {
    this.document.querySelectorAll('[data-bs-theme]').forEach((element) => {
      element.setAttribute('data-bs-theme', 'dark');
    });

    this.document.querySelectorAll("[class*='-light']").forEach((element) => {
      element.className = element.className.replace(/-light/g, '-dark');
    });

    // set light switch input to true
    localStorage.setItem('lightSwitch', 'dark');
  }
}
