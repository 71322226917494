import { Payment, Price } from './payment';
import { Resource } from './resource';
import { Notice } from './notice';
import { Period } from './period';
import { HostDTO } from './booking';
import { Duration, DurationType } from './duration';

export class Service {
  category?: string;
  name?: string;
  description?: string;
  durations: Array<Duration> = [new Duration()];
  members: Array<HostDTO> = new Array<HostDTO>();
  prices: Array<Price> = new Array<Price>();
  image?: string;
}

export class Category {
  name?: string;
  color?: number;
  description?: string;
}

export class ServiceMenu extends Resource {
  categories: Category[] = new Array<Category>();
  services: Service[] = new Array<Service>();
  period: Period = new Period();
  notice: Notice = new Notice();
  slots: Duration = { value: 30, type: DurationType.minutes };
  payment: Payment = new Payment();
}
