export enum TextAlign {
  left = 'left',
  right = 'right',
}

export class Banner {
  background?: string;
  url?: string;
  overlay?: boolean = false;
  color?: string;
  align?: TextAlign = TextAlign.left;
}
