<div class="modal-header">
  <h5 class="modal-title">Embed to website</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    title="close"
    (click)="activeModal.dismiss('close')"
  ></button>
</div>
<div class="modal-body py-0 overflow-hidden">
  <div class="row">
    <div class="col-md-5 border-end p-3">
      <form #form="ngForm" (change)="codeGenerator()" class="px-3">
        <div class="form-group mb-3 mt-3">
          <label for="url" class="">DaySchedule URL</label>
          <div class="input-group input-group-sm">
            <input
              id="url"
              name="url"
              type="text"
              [(ngModel)]="domain"
              class="form-control"
              aria-describedby="urlHelpBlock"
            />
            <div class="input-group-text">dayschedule.com</div>
          </div>
          <span id="urlHelpBlock" class="form-text text-muted small"
            >Your scheduling page URL</span
          >
        </div>
        <div class="form-group mb-3">
          <label class="">Widget type</label>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                name="type"
                id="type_0"
                type="radio"
                class="custom-control-input"
                value="popup"
                [(ngModel)]="options.type"
                aria-describedby="typeHelpBlock"
              />
              <label for="type_0" class="custom-control-label ms-2"
                >popup</label
              >
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                name="type"
                id="type_1"
                type="radio"
                class="custom-control-input"
                value="inline"
                [(ngModel)]="options.type"
                aria-describedby="typeHelpBlock"
              />
              <label for="type_1" class="custom-control-label ms-2"
                >inline</label
              >
            </div>
            <span id="typeHelpBlock" class="form-text text-muted small"
              >Select the widget type to specify how the scheduling calendar
              should be embed on your website</span
            >
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="">Color</label>
          <div class="d-flex justify-content-start">
            <div class="me-3">
              <label for="secondaryColor"></label>
              <input
                name="primary_color"
                type="color"
                id="primary_color"
                value="#0f0980"
                [(ngModel)]="options.color.primary"
                title="Choose primary color"
              />
            </div>
            <div>
              <label for="secondaryColor"></label>
              <input
                name="secondary_color"
                type="color"
                id="secondary_color"
                value="#afeefe"
                [(ngModel)]="options.color.secondary"
                title="Choose secondary color"
              />
            </div>
          </div>
          <span id="radioHelpBlock" class="form-text text-muted small"
            >Specify colors to match the scheduler with your website or
            brand</span
          >
        </div>
        <div class="form-check">
          <label class="form-check-label d-block fw-bold">
            <input
              class="form-check-input"
              type="checkbox"
              name="hideHeader"
              [(ngModel)]="options.hideHeader"
            />
            Hide header
          </label>
          <span class="form-text text-muted small"
            >To hide the scheduling popup header</span
          >
        </div>
      </form>
    </div>
    <div class="col-md-7 py-3">
      <div class="form-group mb-3">
        <label class="">Prerequisites</label>
        <p class="small mb-0">
          Install
          <a
            target="_blank"
            href="https://www.npmjs.com/package/dayschedule-widget"
            >dayschedule-widget</a
          >
          from NPM
        </p>
        <pre
          class="text-danger border rounded p-2"
        ><code>npm i dayschedule-widget</code></pre>
        <p class="small mb-0">
          Or add the JavaScript and CSS from CDN into your website header
        </p>
        <pre
          class="small border rounded text-danger p-2"
        ><code>&#x3C;link href=&#x22;https://cdn.jsdelivr.net/npm/dayschedule-widget&#64;latest/dist/dayschedule-widget.css&#x22; rel=&#x22;stylesheet&#x22; type=&#x22;text/css&#x22; /&#x3E;
&#x3C;script src=&#x22;https://cdn.jsdelivr.net/npm/dayschedule-widget&#64;latest/dist/dayschedule-widget.min.js&#x22; defer&#x3E;&#x3C;/script&#x3E;
                </code></pre>
      </div>
      <div class="form-group mb-3">
        <label for="code" class="">Embed code</label>
        <textarea
          id="code"
          name="code"
          cols="100"
          rows="6"
          class="form-control text-danger"
          [(ngModel)]="code"
          aria-describedby="codeHelpBlock"
          spellcheck="false"
          style="white-space: nowrap; font-size: small"
        ></textarea>
        <span id="codeHelpBlock" class="form-text text-muted small"
          >Add this code on your website header or wherever you want to show the
          day schedule calendar for bookings</span
        >
      </div>
      <div class="form-group mb-3">
        <button class="btn btn-primary btn-sm me-2" (click)="copy()">
          <i class="bi-copy me-2"></i> Copy this code
        </button>
        <a
          class="btn btn-sm btn-link"
          href="https://dayschedule.com/widget"
          target="_blank"
          >More customization options &rarr;</a
        >
      </div>
      <div class="form-group mb-3">
        <label class="">Plugins and examples:</label>
        <div>
          <a
            class="btn btn-sm btn-warning"
            href="https://wordpress.org/plugins/dayschedule-appointment-event-and-service-booking/"
            target="_blank"
          >
            <i class="bi-wordpress me-2"></i>
            Wordpress plugin
          </a>
          <a
            class="btn btn-sm btn-danger ms-2"
            href="https://stackblitz.com/edit/popup-appointment-widget-for-website"
            target="_blank"
          >
            <i class="bi-html5 me-2"></i>
            HTML example
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
