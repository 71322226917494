import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  allowedLinks = ['/billings', '/settings'];

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const authUser = this.authService.getUser();
    const authToken = this.authService.getToken();
    if (authUser && authToken) {
      // Permissions check for users
      if (
        !this.authService.checkPermission(
          { level: route.data.level, permissions: route.data.permissions },
          authUser
        )
      ) {
        this.router.navigate(['/error'], {
          queryParams: { type: 'permissionDenied' },
        }); // role not authorised so redirect to error page
        return false;
      }

      // Subscription check for users
      if (
        authUser.status != 'active' &&
        !this.allowedLinks.some((link) => state.url.startsWith(link))
      ) {
        this.router.navigate(['/error'], {
          queryParams: { type: 'trialExpired' },
        });
        return false;
      }
      return true; // logged in so return true
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
