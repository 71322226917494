<div id="wrapper" class="h-100">
  <app-sidebar *ngIf="isLoggedIn"></app-sidebar>

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <app-topbar *ngIf="isLoggedIn"></app-topbar>

      <router-outlet></router-outlet>
    </div>
    <!-- End of Main Content -->
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->