<div class="container-fluid">
    <ng-container [ngSwitch]="errorType">
      <div class="row" *ngSwitchCase="'trialExpired'">
        <div class="col-md-6 offset-md-3 mt-5">
          <div class="card shadow">
            <div class="card-body text-center">
              <h4 class=" mb-3">Subscription expired!</h4>
              <div class="text-center">
                <p class=" mx-auto">
                  Your subscription has been expired or canceled, purchase a subscription to re-activate your DaySchedule account.
                </p>
                <a class="btn btn-primary mt-2" [routerLink]="['/billings']">Subscribe again</a>
                <a target="_blank" class="btn btn-outline-primary ms-3 mt-2" href="https://dayschedule.com/contact">Contact sales</a>
                <hr />
                <p class="text-muted mx-auto mt-3 small">If you've purchased the subscription already? Just refresh this page or logout > login again.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row" *ngSwitchCase="'permissionDenied'">
        <div class="col-md-6 offset-md-3 mt-5">
          <div class="card shadow">
            <div class="card-body text-center">
              <h2 class=" mb-3">Permission denied!</h2>
              <div class="text-center">
                <p class=" mx-auto">
                  Your do not have access to the last selected page, please check your role or contact your account owner to learn more about permissions.
                </p>
                <a class="btn btn-primary" routerLink="/">Back to Home</a>
                <a target="_blank" class="btn btn-outline-primary ms-3" href="https://dayschedule.com/contact/">Contact support</a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div *ngSwitchDefault>
        <div class="error mx-auto">404</div>
        <div class="text-center">
          <p class="lead mx-auto">Page Not Found</p>
          <p class=" mx-auto">It looks like you found a glitch in the matrix...</p>
          <a class="btn btn-primary" routerLink="/">&larr; Back to Home</a>
        </div>
      </div>
  
    </ng-container>
  </div>