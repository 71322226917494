import { Question } from './question';
import { ResourceSettings } from './resourceSetting';
import { Location } from './location';
import { Webinar } from './webinar';
import { ServiceMenu } from './service';
import { Schedule } from './schedule';
import { Event } from './event';
import { ResourceDTO } from './booking';

export type IResource = Resource &
  Event &
  Webinar &
  ServiceMenu &
  Schedule &
  ResourceDTO;

export enum ResourceType {
  event = 'event',
  webinar = 'webinar',
  service = 'service',
}

export class FormOptions {
  heading: string = 'Registration';
}

export class Resource {
  resource_id?: number;
  account_id?: number;
  page_id?: number;
  user_id?: number;
  name?: string;
  description?: string;
  slug?: string;
  type: ResourceType;
  schedule_id?: number;
  language?: string = 'en-US';
  locations: Array<Location> = new Array<Location>();
  questions: Question[] = new Array<Question>();
  settings: ResourceSettings = new ResourceSettings();
  form: FormOptions = new FormOptions();
  internal_note?: string;
  is_enabled?: boolean = true;
  is_template?: boolean;
  booking_url?: string;
  rank?: number;
  thumbnail?: string;
  is_private?: boolean;
}

export class ResourceClone {
  resource_id?: number;
  page_id?: number;
  user_id?: number;
  name?: string;
  slug?: string;
}
