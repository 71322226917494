import { HostDTO } from './booking';
import { BreakTime } from './breakTime';
import { Notice } from './notice';
import { Payment, Price } from './payment';
import { Period } from './period';
import { Resource } from './resource';
import { Duration, DurationType } from './duration';
import { Capacity } from './capacity';

export const colorsArray = [
  '#FF9CBB',
  '#E2A6E6',
  '#BBC1E8',
  '#A5DFF8',
  '#91E3EE',
  '#6CD5CB',
  '#A6E5BD',
  '#E7F286',
  '#FFEC78',
  '#FFBF69',
];

export const randomColor = () => {
  const random = Math.floor(Math.random() * colorsArray.length);
  return colorsArray[random];
};

export enum EventType {
  group = 'group',
  round_robin = 'round_robin',
  one_on_one = 'one_on_one',
}

export class Member {
  user_id: number;
  priority: number;
}

export class Event extends Resource {
  event_type?: EventType;
  notice: Notice = new Notice();
  slots: Duration = { type: DurationType.minutes, value: 30 };
  durations?: Array<Duration> = [{ type: DurationType.minutes, value: 30 }];
  color?: string = randomColor();
  capacity: Capacity = new Capacity();
  break: BreakTime = new BreakTime();
  period: Period = new Period();
  payment: Payment = new Payment();
  prices: Array<Price> = new Array<Price>();
  members?: Array<Member> = [];
}
