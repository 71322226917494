export class Price {
  name?: string;
  duration?: string;
  amount?: number;
  currency?: string;
}

export class Payment {
  is_enabled?: boolean;
  gateways: Array<string> = new Array<string>();
}
