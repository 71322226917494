import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FcmService } from './services/fcm.service';
import { ColorModeService } from './services/color-mode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'DaySchedule';
  isLoggedIn: boolean = false;
  date: Date = new Date();
  message: any;
  authUser: any;

  public constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService, // Don't delete
    private modalService: NgbModal,
    private fcmService: FcmService,
    private colorModeService: ColorModeService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const authToken = this.authService.getToken();
        this.isLoggedIn =
          authToken != null &&
          !this.authService.authPages.includes(event.url.split('?')[0]);
      }
    });
  }

  ngOnInit() {
    this.colorModeService.setupColorMode();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((title: any) => {
        if (title) {
          this.setTitle(`${title} - DaySchedule`);
        }
        if (this.modalService.hasOpenModals()) {
          this.modalService.dismissAll();
        }
      });

    this.authUser = this.authService.getUser();
    if (this.authUser) {
      this.fcmService.requestPermission().then((token) => {});
      this.listenForMessages();
    }
  }

  listenForMessages() {
    this.fcmService.listenToMessages();
    this.fcmService.currentMessage.subscribe((message) => {
      this.message = message;
    });
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
