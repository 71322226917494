import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Menu } from '../../model/menu';
import { PermissionType, RoleType } from '../../model/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  menus: Array<Menu>;
  types = [
    { name: 'Menu', url: '/' },
    { name: 'Billings', url: '/billings' },
    { name: 'Settings', url: '/settings' },
  ];

  childrens = [
    {
      parent: 'settings',
      path: 'users',
      data: {
        menu: true,
        icon: 'bi-people-fill',
        title: 'Users',
        permissions: [],
      },
    },
    {
      parent: 'settings',
      path: 'profile',
      data: {
        menu: true,
        icon: 'bi-person-fill',
        title: 'Profile',
        permissions: [],
      },
    },
    {
      parent: 'settings',
      path: 'availability',
      data: {
        menu: true,
        icon: 'bi-calendar-day',
        title: 'Availability',
        permissions: [],
      },
    },
    {
      parent: 'settings',
      path: 'affiliates',
      data: {
        menu: true,
        icon: 'bi-gift',
        title: 'Affiliate',
        permissions: [],
      },
    },
    {
      parent: 'settings',
      path: 'apikeys',
      data: {
        menu: true,
        title: 'Api Keys',
        icon: 'bi-terminal',
        permissions: [
          RoleType.Owner,
          RoleType.Admin,
          PermissionType['apikey.read'],
        ],
      },
    },
    {
      parent: 'settings',
      path: 'logs',
      data: {
        menu: true,
        title: 'Audit Logs',
        icon: 'bi-eyeglasses',
        permissions: [
          RoleType.Owner,
          RoleType.Admin,
          PermissionType['apikey.read'],
        ],
      },
    },
    {
      parent: 'settings',
      path: 'delete-account',
      data: {
        menu: true,
        title: 'Delete Account',
        icon: 'bi-trash',
        permissions: [RoleType.Owner],
      },
    },
    {
      parent: 'billings',
      path: 'invoices',
      data: {
        menu: true,
        icon: 'bi-receipt',
        title: 'Invoices',
        permissions: [RoleType.Owner],
      },
    },
  ];

  authUser: any;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authUser = this.authService.getUser();
    this.menus = this.router.config
      .filter((x: any) => x.data?.menu)
      .map((m: any) => this.mapper(m));
    this.menus.push(...this.childrens.map((m: any) => this.mapper(m)));
  }

  mapper(m: any) {
    return {
      name: m.data.title,
      url: m.parent ? `/${m.parent}/${m.path}` : `/${m.path}`,
      icon: m.data.icon,
      permissions: m.data.permissions,
      level: m.data.level,
    };
  }

  checkPermission(menu: Menu, type: any) {
    if (!menu.url) return;

    if (!menu.url.includes(type.url)) return;

    if (
      type.url == '/settings' &&
      menu.url == '/settings' &&
      this.authUser.role != RoleType.Owner
    ) {
      return;
    }

    if (
      type.url === '/' &&
      this.childrens.find((x) => menu.url?.includes(x.parent))
    ) {
      return;
    }
    return this.authService.checkPermission(menu, this.authUser);
  }
}
