import { KeyValue } from './keyValue';
import { Question } from './question';

export class ApiBearerAuth {
  token?: string;
}

export class ApiKeyAuth extends ApiBearerAuth {
  key?: string;
  value?: string;
  add_to?: string;
}

export class ApiBasicAuth extends ApiKeyAuth {
  username?: string;
  password?: string;
}

export class ApiAuth extends ApiBasicAuth {
  type?: string = 'none';
}

export class ApiRequestBody {
  type: string = 'application/x-www-form-urlencoded';
  data: string;
  form: Array<KeyValue> = [];
}

export enum RequestType {
  auth_url = 'auth_url',
  auth_token = 'auth_token',
  profile = 'profile',
  refresh_token = 'refresh_token',
  workflow = 'workflow',
}
export enum IntegrationType {
  app = 'app',
  oauth = 'oauth',
  apikey = 'apikey',
}

export class ApiRequest {
  type?: RequestType;
  url?: string;
  method?: string = 'POST';
  params: Array<KeyValue> = [];
  headers: Array<KeyValue> = [];
  auth: ApiAuth = new ApiAuth();
  body: ApiRequestBody = new ApiRequestBody();
}

export class Environment {
  test: Array<KeyValue> = [];
  prod: Array<KeyValue> = [];
}

export class OauthApp {
  client_id?: string;
  client_secret?: string;
  scopes?: Array<string>;
  redirect_uri?: Array<string>;
}

export class Integration {
  integration_id?: number;
  service_id?: string;
  name?: string;
  description?: string;
  category?: string;
  type?: IntegrationType = IntegrationType.oauth;
  icon?: string;
  plan?: string = 'Pro';
  status?: string = 'dev';
  requests: Array<ApiRequest> = new Array<ApiRequest>();
  env: Environment = new Environment();
  app: OauthApp = new OauthApp();
  form: Array<Question> = [];
  marketplace_url?: string;
  docs_url?: string;
  referral_url?: string;
  is_inherited?: boolean;
}
