import { File } from "./file";

export class Activity {
  activity_id: string;
  contact_id: number;
  type: ActivityType = ActivityType.note;
  title?: string;
  description?: string;
  user?: any;
  file?: File;
  date?: Date;
  status?: ActivityStatus = ActivityStatus.submitted;
  created_at?: Date;
  updated_at?: Date;
}

export enum ActivityType {
  note = 'note',
  call = 'call',
  task = 'task',
}

export enum ActivityStatus {
  submitted = 'submitted',
  scheduled = 'scheduled',
  running = 'running',
  completed = 'completed',
  errored = 'errored',
}
