<div class="row m-0 vh-100">
  <div class="col-lg-4 col-md-5 bg-brand p-0">
    <div class="mt-5 mx-4 mx-md-5">
      <img
        class="img-fluid"
        width="200"
        height="auto"
        src="https://cdn.dayschedule.com/img/dayschedule-logo.png"
        alt="DaySchedule"
      />
    </div>
    <div class="card my-3 mx-4 mx-md-5">
      <div class="card-body">
        <h1 class="h3">Sign up</h1>
        <a
          class="btn mt-2 px-0"
          [href]="googleAuthUri"
          aria-describedby="Sign in with Google"
        >
          <img src="../../static/img/btn_google_signin_dark_normal_web.png" />
        </a>
        <div class="or-container">
          <p class="or mb-0">or</p>
        </div>
        <form
          class="user"
          #form="ngForm"
          (ngSubmit)="onSubmit(form)"
          autocomplete="off"
          ngNativeValidate
        >
          <label class="fw-bold">Sign up with email</label>
          <div class="form-group mb-3">
            <input
              placeholder="Name*"
              type="text"
              class="form-control"
              id="name"
              name="name"
              [(ngModel)]="user.name"
              required
            />
          </div>
          <div class="form-group mb-3">
            <input
              placeholder="Email*"
              type="email"
              class="form-control"
              id="email"
              name="email"
              [(ngModel)]="user.email"
              required
            />
          </div>
          <div class="form-group mb-3">
            <input
              placeholder="Password*"
              type="Password"
              class="form-control"
              name="password"
              id="password"
              [(ngModel)]="user.password"
              required
              minlength="8"
              autocomplete="new-password"
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary w-100 mt-3"
            [disabled]="loading"
          >
            <i
              class="spinner-border spinner-border-sm"
              role="status"
              *ngIf="loading"
            ></i>
            Sign up
          </button>
          <p class="small text-muted my-2">
            By sign up, you are creating a Dayschedule account, and agree to
            <a href="https://dayschedule.com/terms">Terms of Use</a> and
            <a href="https://dayschedule.com/privacy">Privacy Policy</a> .
          </p>
        </form>
        <div class="mt-3">
          Already have an account? <a routerLink="/login">Log in &rarr;</a>
        </div>
        <div class="mt-3 pb-2">
          Forgot your password?
          <a routerLink="/forgot-password">Reset here &rarr;</a>
        </div>
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>
  <div class="col-lg-8 col-md-7 d-none d-md-block bg-body border-start">
    <app-auth-sidebar></app-auth-sidebar>
  </div>
</div>
