import { CalendarEventItems } from './calendar';
import { EventType } from './event';
import { KeyValue } from './keyValue';
import { Location } from './location';
import { ResourceType } from './resource';
import { Duration } from './duration';

export enum BookingStatus {
  confirmed = 'confirmed',
  canceled = 'canceled',
  pending = 'pending',
  completed = 'completed',
}

export enum InviteeStatus {
  confirmed = 'confirmed',
  canceled = 'canceled',
  pending = 'pending',
  noshow = 'noshow',
}

export enum PaymentGateway {
  stripe = 'stripe',
  paypal = 'paypal',
  razorpay = 'razorpay',
}

export class BookingCalendar {
  calendar_id?: string;
  event?: CalendarEventItems;
}

export class Attendees {
  name?: string;
  email: string;
  status?: string; // needsAction, accepted etc.
  organizer?: boolean;
}

export class BookingLinks {
  type: string;
  id: string;
  url?: string;
}

export class Reschedule {
  rescheduled_from?: Date | string;
  rescheduled_by?: string;
  reschedule_reason?: string;
  rescheduled_at?: Date | string;
}

export class Booking {
  booking_id?: number;
  event_id?: string; // Calendar event id
  resource: ResourceDTO = new ResourceDTO();
  host?: HostDTO = new HostDTO();
  calendar?: BookingCalendar;
  color?: string;
  start_at?: Date | string;
  end_at?: Date | string;
  duration?: number;
  location?: Location = new Location();
  subject?: string;
  description?: string;
  status?: string;
  time_zone?: string;
  internal_note?: string;
  created_at?: Date;
  updated_at?: Date;
  notify_all?: boolean;
  booking_url?: string;
  invitees?: Array<Invitee> = new Array<Invitee>();
  icon?: string;
  cancel_reason?: string;
  canceled_at?: Date;
  canceled_by?: string;
  links?: BookingLinks[];
  attendees?: Array<Attendees>;
  reschedules?: Array<Reschedule>;
  recurrence?: Array<string>;
}

export class HostDTO {
  user_id?: number;
  name?: string;
  email?: string;
  avatar?: string;
  time_zone?: string;
  phone?: string;
}

export class ResourceDTO {
  resource_id?: string;
  type?: ResourceType;
  event_type?: EventType | undefined;
  name?: string;
  description?: string;
  slug?: string;
}

export class InviteePayment {
  amount?: number;
  currency?: string;
  gateway?: string;
  reference_id?: string;
  data?: any;
}

export class Invitee {
  invitee_id?: number;
  booking_id?: number;
  name?: string;
  email?: string;
  phone?: string;
  status?: InviteeStatus = InviteeStatus.confirmed;
  reason?: string;
  time_zone?: string;
  guests?: Array<string> = [];
  questions?: Array<any> = [];
  payment?: InviteePayment = new InviteePayment();
  custom_fields?: Array<KeyValue>;
  check_in?: boolean;
}

export class BookingAssigneeRequest {
  user_id?: number;
  reason?: string;
  notify_all?: boolean = true;
}
